import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"


export default function affiliateRegistration() {
    return (
      <Layout>
        <SEO
          title="taxomate referral program"
          description="Referral program for taxomate. Earn 20% recurring commission!"
          image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
          pathname="/affiliate-registration/"
          originalPathname="/affiliate-registration/"
        />

<iframe height="850px" width="100%" frameborder="0" src="https://taxomate.firstpromoter.com"></iframe>


    </Layout>
  );
}